<template>
 <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10" >
          <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
          <div class="row">
              <div class="col-12 ">
                  <app-header></app-header>
              </div>
          </div>
          <!-- Academic section -->
          <section class=" px-0 mx-0 px-xl-5 mx-xl-5  mt-0 mt-md-5 mb-3">
            <div v-if="!loading" class="row pdf-container">
              <div v-for="academicCalendar of academicCalendars[locale]" class="col-12 w-100 h-100 mb-5">
                <iframe class="pdf-iframe w-100 h-100" :src="academicCalendar"></iframe>
              </div>
            </div>
            <div v-else class="row pdf-container">
              <div class="col-12 w-100 h-100">
                <sk-list :height="1" :items-count="1"></sk-list>
              </div>
            </div>
<!--              <div class="container-fluid px-0 px-sm-3 h-100">-->
<!--                      <div class="col-md-7 col-lg-12 text-center mt-3">-->
<!--                          <h2 class="mb-5">-->
<!--                              2020-2021 წლის შემოდგომის სემესტრის აკადემიური კალენდარი-->
<!--                          </h2>-->
<!--                      </div>-->
<!--                      <div class="col-12">-->
<!--                          <div class="academic-calendar bg-light b-30">-->
<!--                              <div class="heading heading-primary mx-0 mx-sm-5">-->
<!--                                  <span class="f-h-bold">2020-2021 შემოდგომის სემესტრი</span>-->
<!--                              </div>-->
<!--                              <div class="mt-4 pt-2 pr-sm-3 ml-sm-5">-->
<!--                                  <table class="table">-->
<!--                                      <thead>-->
<!--                                          <tr>-->
<!--                                              <th class="text-center">სასწავლო კვირა</th>-->
<!--                                              <th class="text-center">თარიღი</th>-->
<!--                                              <th class="text-center">განმარტება</th>-->
<!--                                          </tr>-->
<!--                                      </thead>-->
<!--                                      <tbody>-->
<!--                                          <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020–19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                          <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020 – 19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                         <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020–19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                          <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020 – 19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                         <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020–19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                          <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020 – 19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                         <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020–19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                          <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020 – 19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                         <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020–19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->
<!--                                          <tr>-->
<!--                                              <td class="text-center">1</td>-->
<!--                                              <td class="text-center">14.09.2020 – 19.09.2020</td>-->
<!--                                              <td class="text-center">სასწავლო კვირა</td>-->
<!--                                          </tr>-->

<!--                                      </tbody>-->
<!--                                  </table>-->
<!--                              </div>-->
<!--                          </div>-->
<!--                      </div>-->
<!--                  </div>-->
<!--              </div>-->
          </section>
          <!-- <div class="row m-4 pdf-container">
            <div class="col-12">
              <iframe class="pdf-iframe" :src="helpers.getLocalizedField(currentSemester, 'academic_calendar_link')"></iframe>
            </div>
          </div> -->
      </main>
      <!-- MAIN CONTETN end -->
      </div>
  </div>

</template>

<script>
import sidebar from '../components/navbar/sidebar';
import appHeader from '../components/app-header';
import pageHeader from '../components/page/page-header';
import navHeader from '../components/navbar/nav-header';
import breadcrumb from '../components/page/breadcrumb';
import pdf from 'vue-pdf';

import { mapGetters } from 'vuex';
import SkList from '../components/skeletons/sk-list';

export default {
  name: 'academic-calendar',
  components: { SkList, sidebar, appHeader, pageHeader, navHeader, breadcrumb, pdf},

  computed: {
    pageData() {
      return {
        title: this.$t('academic-calendar.title'),
        breadcrumb: [{
          icon: '',
          title: this.$t('home.title'),
          link: '/',
          isActive: false,
        },
          {
          icon: '',
          title: this.$t('academic-calendar.title'),
          link: '/academic-calendar',
          isActive: true,
        }],
      };
    },
    ...mapGetters({
      locale: 'language/locale',
    }),
  },

  data() {
    return {
      academicCalendars: [],
      loading: false
    }
  },

  mounted() {
    this.loading = true;
    this.$store.dispatch('semester/loadAcademicCalendars').then(response => {
      this.academicCalendars = response.data.data;
    })
    .finally(() => {
      this.loading = false;
    });
  }
};
</script>

<style scoped>
  .pdf-container {
    height: 73vh;
  }
</style>
